import React from 'react';
import 'antd/dist/antd.css';
import './App.css';
import _ from 'lodash';
import { Layout, Card, Typography, List, Select, Button, Row, Col, Form, Input, Tag, Statistic, Icon, Switch, Checkbox } from 'antd';
import ReactExport from "react-export-excel";

import dataBooksDB from './data/books.json'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const dataBooks = dataBooksDB.sort((a, b) => b.PDATE - a.PDATE).map(x => { x.selectedBook = false; return x })

const { Header, Footer, Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;
const { Meta } = Card;
const { Search } = Input;

const listTypes = ['manuel', 'lit'];
const listPublic = ['lycee', 'college']
const listPublicLabel = [{label: 'Lycée', value: 'lycee'}, {label: 'Collège', value: 'college'}]

var listClasses = _.uniq(dataBooks.map(livre => livre.NIVEAU).filter(n => (n != null && n !== '')));
var optionClasses = listClasses.map(c => <Option key={c}>{c}</Option>);
// listClasses.unshift('na')
// optionClasses.unshift(<Option key='na'>Indéterminé</Option>)
const listMatieres = _.uniq(dataBooks.map(livre => livre.MATIERE).filter(n => (n != null && n !== '')));
const optionMatieres = listMatieres.map(c => <Option key={c}>{c}</Option>);

class Catalog extends React.Component {

    constructor(props) {
        super(props);

        var currentFilters = { typeOfBook: listTypes, publie: false, classes: listClasses, matieres: listMatieres, title: "", auteur: "", editeur: "", bookpublic: listPublic }

        const s = new URLSearchParams(window.location.search)
        var newf = {};
        // ?typeOfBook=manuel&publie=1&classes=5e&matieres=Français&title=chevalier&auteur=hugo&editeur=hachette
        ['typeOfBook', 'publie', 'classes', 'matieres', 'title', 'auteur', 'editeur', 'bookpublic'].forEach(f => {
            if (s.has(f)) {
                newf[f] = s.get(f)
            }
        })
        if (!_.isEmpty(newf)) {
            const newFilters = this.computeFilters(newf, currentFilters)
            const dataBooksFiltered = this.computeDataBooksFiltered(newFilters)
            this.state = { data: dataBooksFiltered, filters: newFilters }
        } else {
            this.state = { data: dataBooks, filters: currentFilters }
        }
    }

    // onChangeType = (typeOfBook) => { this.applyFilters({ typeOfBook: typeOfBook }); };
    onChangeTypeManuel = (checked) => {
        const typeOfBook = checked ? ['manuel', ...this.state.filters.typeOfBook] : this.state.filters.typeOfBook.filter(t => t !== 'manuel');
        this.applyFilters({ typeOfBook: typeOfBook });
    }
    onChangeTypeLit = (checked) => {
        const typeOfBook = checked ? ['lit', ...this.state.filters.typeOfBook] : this.state.filters.typeOfBook.filter(t => t !== 'lit');
        this.applyFilters({ typeOfBook: typeOfBook });
    }
    onChangePublie = (checked) => {
        const publie = checked ? true : false;
        this.applyFilters({ publie: publie });
    }

    onChangeClasse = (classes) => { this.applyFilters({ classes: classes }); };
    onChangeMatiere = (matieres) => { this.applyFilters({ matieres: matieres }); };

    onChangePublic = (bookpublic) => { this.applyFilters({ bookpublic: bookpublic }) }

    onSearchTitle = (value) => { this.applyFilters({ title: value }); };
    onChangeTitle = (event) => {
        window.gtag('event', 'LiveTitre', { 'event_category': 'UserSearch', 'event_label': event.target.value })
        this.applyFilters({ title: event.target.value });
    };

    onSearchAuteur = (value) => { this.applyFilters({ auteur: value }); };
    onChangeAuteur = (event) => {
        window.gtag('event', 'LiveAuteur', { 'event_category': 'UserSearch', 'event_label': event.target.value })
        this.applyFilters({ auteur: event.target.value });
    };

    onSearchEditeur = (value) => { this.applyFilters({ editeur: value }); };
    onChangeEditeur = (event) => {
        window.gtag('event', 'LiveEditeur', { 'event_category': 'UserSearch', 'event_label': event.target.value })
        this.applyFilters({ editeur: event.target.value });
    };

    resetFilters = () => { this.setState({ data: dataBooks, filters: { typeOfBook: listTypes, classes: listClasses, matieres: listMatieres, title: "", auteur: "", editeur: "", bookpublic: listPublic } }) };

    computeFilters = (oneFilter, currentFilters) => {
        return {
            typeOfBook: currentFilters.typeOfBook, publie: currentFilters.publie,
            classes: currentFilters.classes, matieres: currentFilters.matieres,
            title: currentFilters.title, auteur: currentFilters.auteur, editeur: currentFilters.editeur,
            bookpublic: currentFilters.bookpublic,
            ...oneFilter
        }
    }

    computeDataBooksFiltered = (newFilters) => {
        return dataBooks.filter(item =>
        (newFilters.typeOfBook.includes(item.TYPE) &&
            ((newFilters.publie && (item.STATUT === "1" || item.STATUT === "1,0")) || (!newFilters.publie)) &&
            (newFilters.classes.includes(item.NIVEAU) || item.NIVEAU == null) && // newFilters.classes.includes('na'))
            (newFilters.matieres.includes(item.MATIERE) || item.MATIERE == null) &&
            (newFilters.title === "" || (item.TITRE != null && item.TITRE !== "" && item.TITRE.toLowerCase().includes(newFilters.title.toLowerCase()))) &&
            (newFilters.auteur === "" || (item.AUTEUR != null && item.AUTEUR !== "" && item.AUTEUR.toLowerCase().includes(newFilters.auteur.toLowerCase()))) &&
            (newFilters.editeur === "" || (item.EDITEUR != null && item.EDITEUR !== "" && item.EDITEUR.toLowerCase().includes(newFilters.editeur.toLowerCase()))) &&
            (newFilters.bookpublic.includes(item.PUBLIC) || item.PUBLIC == null)
        )
        )
    }

    applyFilters = (oneFilter) => {
        const newFilters = this.computeFilters(oneFilter, this.state.filters)
        const dataBooksFiltered = this.computeDataBooksFiltered(newFilters)
        this.setState({ data: dataBooksFiltered, filters: newFilters })
    }

    genDescription = (livre) => {

        return livre.TYPE === 'manuel' ?
            <span>Niveau : <Text strong>{livre.NIVEAU}</Text><br />Matière : <Text strong>{livre.MATIERE}</Text><br />Editeur : <Text strong>{livre.EDITEUR}</Text><br />EAN : <Text strong>{livre.EAN}</Text></span> :
            <span>Auteur : <Text strong>{livre.AUTEUR}</Text><br />Editeur : <Text strong>{livre.EDITEUR}</Text><br />EAN : <Text strong>{livre.EAN}</Text></span>;
    }

    toggleItem = (e, item) => {
        // console.log(item)
        // console.log(e.target)
        this.setState({
            data: this.state.data.map(b => {
                if (b.EAN === item.EAN) {
                    b.selectedBook = !b.selectedBook
                }
                return b
            })
        })
    }

    render() {
        return (
            <div>
                <Layout>
                    <Header>
                        <Row>
                            <Col span={12}>
                                <a href="https://www.sondo.fr"><img src="sondo-logo.jpg" alt="Sondo" style={{ maxWidth: '100%' }} /></a>
                            </Col>
                            <Col span={12}>
                                <Title className="blue title" >Catalogue Sondo</Title>
                            </Col>
                        </Row>
                    </Header>
                    <Content>
                        <div style={{ background: '#ECECEC', padding: '30px' }}>
                            <Card title={<span className="blue">Filtres</span>}
                                actions={[<Button type='Primary' onClick={this.resetFilters}>Retirer tous les filtres</Button>]}
                            >
                                <Form layout="inline">
                                    {/* <Form.Item label="Type de livre">
                                        <Select
                                            mode="multiple"
                                            style={{ width: 335 }}
                                            placeholder="Veuillez sélectionner un type de livre"
                                            defaultValue={this.state.filters.typeOfBook}
                                            onChange={this.onChangeType}
                                            value={this.state.filters.typeOfBook}
                                        >
                                            <Option key="manuel">Manuels scolaires</Option>
                                            <Option key="lit">Livres de littérature</Option>
                                        </Select><br />
                                    </Form.Item> */}
                                    <Form.Item label="Manuels scolaires">
                                        <Switch defaultChecked onChange={this.onChangeTypeManuel} />
                                    </Form.Item>
                                    <Form.Item label="Livres de littérature">
                                        <Switch defaultChecked onChange={this.onChangeTypeLit} />
                                    </Form.Item>
                                    <Form.Item label="Titre" name="titre">
                                        <Search onSearch={this.onSearchTitle} defaultValue="" value={this.state.filters.title} onChange={this.onChangeTitle} ></Search>
                                    </Form.Item>
                                    <Form.Item label="Auteur" name="auteur">
                                        <Search onSearch={this.onSearchAuteur} defaultValue="" value={this.state.filters.auteur} onChange={this.onChangeAuteur} ></Search>
                                    </Form.Item>
                                    <Form.Item label="Editeur" name="editeur">
                                        <Search onSearch={this.onSearchEditeur} defaultValue="" value={this.state.filters.editeur} onChange={this.onChangeEditeur} ></Search>
                                    </Form.Item>
                                    <br />
                                    <Form.Item label="Public">
                                        <Checkbox.Group options={listPublicLabel} defaultValue={listPublic} onChange={this.onChangePublic} />
                                    </Form.Item>
                                    <Form.Item label="Uniquement les livres publiés">
                                        <Switch onChange={this.onChangePublie} />
                                    </Form.Item>
                                    <Form.Item label="Classe">
                                        <Select
                                            mode="multiple"
                                            style={{ width: 335 }}
                                            placeholder="Veuillez sélectionner une classe"
                                            defaultValue={listClasses}
                                            onChange={this.onChangeClasse}
                                            value={this.state.filters.classes}
                                        >
                                            {optionClasses}
                                        </Select><br />
                                    </Form.Item>
                                    <Form.Item label="Matière">
                                        <Select
                                            mode="multiple"
                                            style={{ width: 335 }}
                                            placeholder="Veuillez sélectionner une matière"
                                            defaultValue={listMatieres}
                                            onChange={this.onChangeMatiere}
                                            value={this.state.filters.matieres}
                                        >
                                            {optionMatieres}
                                        </Select><br />
                                    </Form.Item>

                                </Form>
                            </Card>
                        </div>
                        <div style={{ background: '#ECECEC', padding: '30px' }}>
                            <List
                                header={<Row>
                                    <Col span={12}>
                                        <Statistic suffix='Livres filtrés' value={this.state.data.length} valueStyle={{ color: "#005eb8", textAlign: 'left' }} prefix={<Icon type="file-done" />} />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic suffix='Livres sélectionnés' value={this.state.data.filter(l => l.selectedBook).length} valueStyle={{ color: "#005eb8", textAlign: 'right' }} prefix={<Icon type="shopping-cart" />} />
                                    </Col>
                                    <Col span={4} style={{ textAlign: 'right' }}>
                                        <ExcelFile element={<Button>Télécharger la sélection</Button>} filename="SONDO_Selection_Livres" >
                                            {/* College : Version	Service	Année	Nom	Ville	Département	Pays */}
                                            <ExcelSheet data={[{ "Version": "1", "Service": "Sondo", "Année": new Date().getFullYear(), "Nom": "", "Ville": "", "Département": "", "Pays": "" }]} name="College">
                                                <ExcelColumn label="Version" value="Version" />
                                                <ExcelColumn label="Service" value="Service" />
                                                <ExcelColumn label="Année" value="Année" />
                                                <ExcelColumn label="Nom" value="Nom" />
                                                <ExcelColumn label="Ville" value="Ville" />
                                                <ExcelColumn label="Département" value="Département" />
                                                <ExcelColumn label="Pays" value="Pays" />
                                            </ExcelSheet>
                                            <ExcelSheet data={this.state.data.filter(l => l.selectedBook && l.TYPE === 'manuel')} name="Manuels_Scolaire">
                                                <ExcelColumn label="NIVEAU" value="NIVEAU" />
                                                <ExcelColumn label="MATIERE" value="MATIERE" />
                                                <ExcelColumn label="EAN" value="EAN" />
                                                <ExcelColumn label="TITRE" value="TITRE" />
                                                <ExcelColumn label="EDITEUR" value="EDITEUR" />
                                                <ExcelColumn label="DEJA DISPONIBLE" value={(item) => (item.STATUT !== "1" && item.STATUT !== "1,0") ? "" : "1"} />
                                                <ExcelColumn label="SELECTION COLLEGE" value="selectedBook" />
                                            </ExcelSheet>
                                            <ExcelSheet data={this.state.data.filter(l => l.selectedBook && l.TYPE === 'lit')} name="Livres_Litterature">
                                                <ExcelColumn label="EAN" value="EAN" />
                                                <ExcelColumn label="TITRE" value="TITRE" />
                                                <ExcelColumn label="AUTEUR" value="AUTEUR" />
                                                <ExcelColumn label="EDITEUR" value="EDITEUR" />
                                                <ExcelColumn label="SELECTION COLLEGE" value="selectedBook" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </Col>
                                </Row>}
                                // grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 6, xxl: 6 }}
                                grid={{ gutter: 16, xs: 2, sm: 2, md: 3, lg: 3, xl: 6, xxl: 6 }}
                                // grid={{ gutter: 16, column: 4 }}
                                pagination={{
                                    onChange: page => {
                                        //console.log(page);
                                    },
                                    pageSize: 24,
                                }}
                                dataSource={this.state.data}
                                renderItem={item => (
                                    <List.Item id={item.EAN} onClick={(event) => this.toggleItem(event, item)} className={item.selectedBook ? "selected-book" : ""} >
                                        <Card style={{ height: 500 }} className={item.selectedBook ? "selected-book" : ""}
                                            cover={
                                                <div className="coverblock" >
                                                    <Tag color="#005eb8" className="covertag" visible={item.STATUT !== "1" && item.STATUT !== "1,0"}>Bientôt disponible</Tag>
                                                    <Icon className={item.selectedBook ? "selectedtag" : "selectedtag-hidden"} type="check-circle" theme="twoTone" />
                                                    <img className='coverimg' src={"covers/" + item.EAN + ".jpg"} alt={item.TITRE}
                                                        onError={(e) => { e.target.onerror = null; e.target.src = "covers/alt-image.jpg"; }} />
                                                </div>
                                            } >
                                            <Meta title={item.TITRE} description={this.genDescription(item)} />
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Content>
                    <Footer>© 2019 MOBiDYS</Footer>
                </Layout>
            </div>
        );
    }

}

export default Form.create()(Catalog);

//export default Catalog;
